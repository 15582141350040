<template>
  <v-btn
    class="button-rounded f2gbutton"
    :class="[customClass, { 'text-capitalize': textCapitalize }]"
    :outlined="outline"
    :color="getTextColor"
    :disabled="disabled"
    :fab="fab"
    :text="flat"
    :loading="loading"
    :small="small"
    @click="clickedButton"
  >
    <F2gIcon
      v-if="prependIcon"
      class="mr-3"
      :value="prependIcon"
      :file="prependIconFile"
      :stroke="getTextColor"
      :fill="prependIconFile === 'icons_solid.svg' ? fillIconColour : 'none'"
      :solid="prependIconFile === 'icons_solid.svg'"
      :width="25"
      :height="25"
    />
    {{ text }}
  </v-btn>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "F2gButton",
  props: {
    customClass: {
      required: false,
      type: String,
    },
    disabled: {
      default: false,
      required: false,
      type: Boolean,
    },
    fab: {
      default: false,
      required: false,
      type: Boolean,
    },
    flat: {
      default: false,
      required: false,
      type: Boolean,
    },
    loading: {
      default: false,
      required: false,
      type: Boolean,
    },
    outline: {
      default: false,
      required: false,
      type: Boolean,
    },
    small: {
      default: false,
      required: false,
      type: Boolean,
    },
    text: {
      required: false,
      type: String,
    },
    textColor: {
      default: "white",
      required: false,
      type: String,
    },
    textCapitalize: {
      default: false,
      required: false,
      type: Boolean,
    },
    prependIcon: { default: "", required: false, type: String },
    prependIconFile: {
      default: "icons_solid.svg",
      required: false,
      type: String,
    },
    fillIconColour: {
      default: "black",
      required: false,
      type: String,
    },
  },
  computed: {
    ...mapGetters({
      ownerColors: "auth/getOwnerColors",
    }),
    getTextColor() {
      let color = "";
      if (this.outline) {
        switch (this.customClass) {
          case "secondary":
            color = this.$CONSTANTS.COLOUR.LIGHT_GRAY;
            break;
          case "secondary-dark":
            color = this.$CONSTANTS.COLOUR.DARK_GRAY_1_F2G;
            break;
          case "info":
            color = this.$CONSTANTS.COLOUR.BLUE_2_F2G;
            break;
          case "error":
            color = this.$CONSTANTS.COLOUR.RED_ERROR;
            break;
          case "warning":
            color = this.$CONSTANTS.COLOUR.ORANGE_1_F2G;
            break;
          case "default":
            color = this.textColor;
            break;
          default:
            color = this.ownerColors.ownerPrimaryColor;
            break;
        }
      } else {
        if (this.customClass === "f2gGreen") {
          color = this.$CONSTANTS.COLOUR.GREEN_1_F2G;
        } else if (this.customClass == "blueVolvo") {
          color = this.$CONSTANTS.COLOUR.BLUE_1_F2G;
        } else {
          color = this.textColor;
        }
      }
      return color;
    },
  },
  methods: {
    clickedButton() {
      this.$emit("click");
    },
  },
};
</script>

<style lang="scss" scoped>
.f2gButton {
  color: white !important;
}
</style>
